<template>
<CContainer>
  <CRow>
      <CCol col="12">
        <CCard>
          <CCardHeader>
            <h3 class="text-center">Emails Bloqueados</h3>
          </CCardHeader>
        </CCard>
      </CCol>
   </CRow>
   <CRow>
      <CCol col="6">
        <CCard>
          <CCardHeader>
            <h5 class="text-center">Bloquear un Email</h5>
          </CCardHeader>
          <CCardBody>
                <template v-for="(name, key) in radioNamesMetodoEnvio">
                <CRow form class="form-group" :key="name">
                  <CCol sm="12">
                    {{name}}
                  </CCol>
                  <CInputRadioGroup
                    class="col-12"
                    :options="optionsMetodoEnvio"
                    :custom="true"
                    :checked="`Option ${key}`"
                    :inline="key % 2 === 0"
                    :checked.sync="metodo_envido_checked_radio"
                  />
                </CRow>
              </template>
                <template v-if="changeRadioMetodoEnvio == 1">
                <hr>
              <CForm validate @submit.prevent="crearNuevoContactoIndividual()">
                <CRow>
                 <CCol col="12">
                     <CInput
                        label="Email a Bloquear"
                        placeholder="Ingresa Email a Bloquear"
                        autocomplete="off"
                        minlength="3"
                        maxlength="80"
                        required
                        was-validated
                        v-model="formCrearContactoIndividual.email"
                        />
                     <hr>
                     <!--<CInput
                        label="Motivo a Bloquear"
                        placeholder="Ingresa Motivo a Bloquear"
                        autocomplete="off"
                        minlength="3"
                        maxlength="80"
                        required
                        was-validated
                        v-model="formCrearContactoIndividual.motivo"
                        />
                     <hr>-->
                     <div class="text-center">
                         <CButton size="sm" color="success" type="submit">
                            Bloquear Contacto
                        </CButton>
                     </div>
                 </CCol>
              </CRow>
              </CForm>
              </template>  
              <template v-if="changeRadioMetodoEnvio == 2">
                <hr>
                <CForm validate @submit.prevent="crearNuevoContactosArchivos">
                  <CRow>
                  <CCol col="12">
                      <label for="">Cargar una base de datos con una columna en formato Excel o CSV(Separados por coma ,)</label>
                      <hr>
                  </CCol>
                  <CCol col="12">
                       <label for="">Seleccione un Archivo</label>
                       <input type="file" name="" id="" v-on:change="onChangeArchivoGrupo" :key="fileInputKey"  accept=".xlsx, .xls, .csv">
                        <hr>
                  </CCol>
                  <hr>
                  <CCol col="12">
                      <label for="">
                          <strong>- La primera Columna (A) debe ser siempre el email (Obligatorio)</strong><br>
                      </label>
                      <hr>
                  </CCol>
                  <CCol col="12" class="text-center">
                      <CButton size="sm" color="success" type="submit">
                          Cargar Archivo
                      </CButton>
                  </CCol>
                </CRow>
              </CForm>
              </template>  
          </CCardBody>
        </CCard>
      </CCol>
   </CRow>
     <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader>
           <CRow class="justify-content-center">
              <CCol col="6">
                <h5 class="text-center">Cantidad de Emails Bloqueados: {{$formatterNumeroTipoCreditos(file_all_grupo.cantidad)}}</h5>
              </CCol>
           </CRow>
           <CRow class="justify-content-between">
              <CCol col="6">
                <CButton size="sm" class="text-center" color="success" @click="generarReporteIntegrantes()" v-if="file_all_grupo.cantidad > 0">
                    Descargar {{$formatterNumeroTipoCreditos(file_all_grupo.cantidad)}} Emails Bloqueados</h5>
                 </CButton>
              </CCol>
              <CCol col="3">
                 <!--<CSelect
                        label="Limite Consulta"
                        placeholder="Limite Consulta"
                        :options="listaLimiteQuery"
                        required
                        was-validated
                        @change="filtroItemsQuery"
                        :style="`display:${validaLimiteQuery};`"
                        :value.sync="historial_select"
                        >
                  </CSelect>-->  
              </CCol>
           </CRow>
        </CCardHeader>
        <CCardBody>
          <CDataTable
            hover
            striped
            :items="items"
            :fields="fields"
            :items-per-page="10"
            :pagination="{ doubleArrows: false, align: 'center'}"
            column-filter
            @page-change="pageChange"
          >
          <template #created_at="{item}">
              <td>
                 {{formatoFecha(item.created_at)}}
              </td>
          </template>
          <template #email_bloquear-filter="{item}">
                <br>
                 <input type="text" class="form-control form-control-sm" v-model="filtro_email" @keyup="filtrobusquedalistanegra"> 
          </template>
          <template #acciones="{item}">
            <td>
              <CButton size="sm" color="danger" @click="abrirmodalEliminarGrupo(item)">
                    Eliminar Email
              </CButton>
            </td>
          </template>
          </CDataTable>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
  <div>
          <CToaster :autohide="5000" class="d-inline-block">
             <template v-for="toast in mostrarNotificacion">
                <CToast
                  :key="'toast'+toast"
                  :show="true"
                  color="success"
                  header="Notificacion Exitosa"
                >
                  {{mensaje_success}}
                </CToast>
              </template>   
              <template v-for="toast1 in mostrarNotificacionAlerta">
                <CToast
                  :key="'toast1'+toast1"
                  :show="true"
                  color="warning"
                  header="Notificacion Alerta"
                >
                  {{mensaje_warning}}
                </CToast>
              </template>
              <template v-for="toast2 in mostrarNotificacionError">
                <CToast
                  :key="'toast2'+toast2"
                  :show="true"
                  color="danger"
                  header="Notificacion Alerta"
                >
                  Error Comunicarse Con Soporte
                </CToast>
              </template>  
          </CToaster>
          <CModal
            :show.sync="modal_eliminar_grupo"
            :no-close-on-backdrop="true"
            :centered="true"
            title="Modal title 2"
            size="sm"
            color="dark"
          >
            <CRow>
              <CCol col="12">
                Usted esta Apunto de Eliminar el Integrante : <strong>{{form_eliminar_integrante.email}}</strong>
              </CCol>
            </CRow>
            <template #header>
              <h6 class="modal-title">Eliminar Integrante</h6>
              <CButtonClose @click="modal_eliminar_grupo = false" class="text-white"/>
            </template>
            <template #footer>
              <CButton @click="modal_eliminar_grupo = false" color="danger">Cancel</CButton>
              <CButton  color="success" @click="eliminarIntegrantePorId()">Aceptar</CButton>
            </template>
          </CModal>
  </div>
</CContainer>

</template>


<script>
import EventBus from '../../modules/bus';
export default {
  name: 'ListaNegra',
  data () {
    return {
      items: [],
      fields: [
        { key: 'email', label: 'Email', _classes: 'font-weight-bold' },
        { key: 'motivo', label: 'Motivo'},
        { key: 'fecha_sistema',label:'Fecha Bloqueado' },
        { key: 'acciones',label:'',
          _style: { width: '1%' },
          sorter: false,
          filter: false 
        }
      ],
      activePage: 1,
      collapseDuration: 0,
      nombre_grupo:'',
      mostrarNotificacion: 0,
      mostrarNotificacionAlerta: 0,
      mostrarNotificacionError:0,
      mensaje_warning:'Debe Completar Los Datos Vacio',
      mensaje_success:'Grupo Creado Correctamente.',
      modal_eliminar_grupo:false,
      form_eliminar_integrante:{
        email:'',
        id:'',
      },
      optionsMetodoEnvio: [{label: 'Individual',value:'individual'},{label: 'Cargue Archivo',value:'archivo'}],
      radioNamesMetodoEnvio: ['Selecciona el Metodo de Bloquear Emails'],
      metodo_envido_checked_radio:'individual',
      itemsPais: [{value:'57',label:'Colombia (57)'},{value:'58',label:'Venezuela (58)'}],
      formCrearContactoIndividual:{
          email:'',
          motivo:'',
      },
      formCrearContactosArchivo:{
        archivo:null
      },
      grupo:{
        nombre: '',
        id: '',
      },
      file_all_grupo:{},
      fileInputKey:0,
      descargado:null,
      listaLimiteQuery:[],
      validaLimiteQuery:'none',
      historial_select:'1,1000',
      filtro_email:'',
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler (route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page)
        }
      }
    }
  },
  methods: {
    getBadge (status) {
      switch (status) {
        case 1: return 'success'
        case 0: return 'danger'
      }
    },
    rowClicked (item, index) {
      this.$router.push({path: `users/${index + 1}`})
    },
    pageChange (val) {
      this.$router.push({ query: { page: val }})
    },
    getInitListaNegra(search = ''){
        this.$store.dispatch('auth/loading_http','visible');
        this.$http.post(this.$httpUrlMail+'/mail/lista/negra/numeros/index',{
          params:{
            search:search
          }
        },{ withCredentials: false }).then(response => {
             this.items = response.data.lista;
             this.file_all_grupo.cantidad = response.data.cantidad;
             this.items.map((item,id) => {  
              if (id%2 == 0) {
                item['_classes'] = 'table-now'; 
              }
              return item;
              })
              //this.generarLimites();
             //this.items = this.items.map((item, id) => { return {...item, id}})
        }).catch(()=>{
             
        }).finally(() =>this.$store.dispatch('auth/loading_http','hidden'));
    },
    getListaNegra(){
        this.$http.post(this.$httpUrlMail+'/mail/lista/negra/numeros/index',{
          params:{
            search:''
          }
        },{ withCredentials: false }).then(response => {
             this.items = response.data.lista;
             this.file_all_grupo.cantidad = response.data.cantidad;
             this.items.map((item,id) => {  
              if (id%2 == 0) {
                item['_classes'] = 'table-now'; 
              }
              return item;
              })
             //this.items = this.items.map((item, id) => { return {...item, id}})
        }).catch(()=>{
             
        })
    },
    generarReporteIntegrantes(){
      //this.$store.dispatch('auth/loading_http','visible');
      let id = this.grupo.id;
      this.descargado = null;
        this.$http.get(this.$httpUrlMail+'/mail/lista/negra/reporte',{ withCredentials: false }).then(response => {
             let data = response.data;
             if (data == 'OK') {
                  this.mostrarNotificacion++
                  this.mensaje_success='El Reporte Se Esta Generando Correctamente Por Favor Espere Para que se Descargue de Inmediato.'
                  this.descargado = null;
                  this.$store.dispatch('auth/loading_http','visible');
                  let timerId = setInterval(() => {this.$store.dispatch('auth/loading_http','visible');this.getreporte()}, 5000);
                  let time2 = setInterval(() => {
                    if (this.descargado != null) {
                      clearInterval(timerId); 
                      clearInterval(time2); 
                      this.descargado = null;
                      //this.$store.dispatch('auth/loading_http','hidden')
                    }
                  }, 2000);
                  // después de 5 segundos parar
                  setTimeout(() => { clearInterval(timerId);clearInterval(time2);}, 500000);
             }else{
                  this.mostrarNotificacionAlerta++
                  this.mensaje_warning='No Fue Posible Descargar El Reporte'
             }
        }).catch(()=>{
             
        })//.finally(() =>this.$store.dispatch('auth/loading_http','hidden'));
    },
    getreporte(){
        this.$store.dispatch('auth/loading_http','visible');
        this.$http.get(this.$httpUrlMail+'/mail/lista/negra/reporte/consultar',{ withCredentials: false }).then(response => {
          let data = response.data;
          //console.log(data);
          //console.log(this.descargado);
          if (data.trim() != "") {
            if (data != "NO") {
              if (this.descargado == null) {
                  let nombre = data; 
                  var url = this.$httpUrlMail + `/reporte/descargar/${nombre}`;
                  window.open(url, '_blank');
                  this.descargado = data;
                  this.$store.dispatch('auth/loading_http','hidden')
              }
            }
          }
        }).catch(()=>{
             //console.log(error);
        })//.finally(() =>this.$store.dispatch('auth/loading_http','hidden'));
    },
    toggleDetails (item) {
      this.$set(this.items[item.id], '_toggled', !item._toggled)
      this.collapseDuration = 300
      this.$nextTick(() => { this.collapseDuration = 0})
    },
    eliminarIntegrantePorId(){
      this.modal_eliminar_grupo = false
      this.$store.dispatch('auth/loading_http','visible');
      this.$http.delete(this.$httpUrlMail+'/mail/lista/negra/eliminar/'+this.form_eliminar_integrante.id,{ withCredentials: false }).then(response =>{
        let data = response.data;
        if (data == "OK") {
            let nombre = this.form_eliminar_integrante.email;
            this.form_eliminar_integrante.id = ''
            this.form_eliminar_integrante.email = ''
            this.mostrarNotificacion ++;
            this.getListaNegra();
            this.mensaje_success=`Numero ${nombre} Desbloqueado Correctamente.`;
            //setTimeout(() => {this.mensaje_success='Grupo Creado Correctamente.'},8000)
        }else{
              let mensajeError = '';
              let dataError = response.data;
              for (const key in dataError) {
                if (Object.hasOwnProperty.call(dataError, key)) {
                  const element = dataError[key];
                  //mensajeError += `${element.mensaje} \n`
                  for (const iterator of element) {
                    mensajeError += `${iterator} \n`
                  }
                }
              }
              this.mensaje_warning = mensajeError
              this.mostrarNotificacionAlerta += 1;
        }
      }).catch(error=>{this.mostrarNotificacionError ++;console.log(error);}).finally(() =>this.$store.dispatch('auth/loading_http','hidden'))
    },
    formatoFecha(fecha){
      let fechaformato = fecha.split('.')[0];
      return fechaformato.replace('T',' ');
    },
    abrirmodalEliminarGrupo(item){
      this.modal_eliminar_grupo = true;
      this.form_eliminar_integrante.email = item.email;
      this.form_eliminar_integrante.id = item.id;
    },
    crearNuevoContactoIndividual(evt){
      evt = (evt) ? evt : window.event;
      evt.target.disabled = true;
      if(this.validateEmail(this.formCrearContactoIndividual.email) == false){
        evt.target.disabled = false;
        this.mensaje_warning = 'El Email No es Valido'
        this.mostrarNotificacionAlerta += 1;
        return;
      }
      /*if (this.formCrearContactoIndividual.motivo.trim() == "") {
          evt.target.disabled = false;
          this.mensaje_warning = 'El Motivo No Puede Estar Vacio'
          this.mostrarNotificacionAlerta += 1;
          return;
      }*/
      this.$store.dispatch('auth/loading_http','visible');
      this.$http.post(this.$httpUrlMail+'/mail/lista/negra/numeros',{
        email:this.formCrearContactoIndividual.email,
        motivo:this.formCrearContactoIndividual.motivo,
      },{ withCredentials: false }).then(response =>{
        let data = response.data;
        if (data == "OK") {
            this.mensaje_success='Contacto Individual Bloqueado Correctamente.'
            evt.target.disabled = false;
            this.nombre_grupo = '';
            this.mostrarNotificacion ++;
            this.formCrearContactoIndividual.email = ''
            this.formCrearContactoIndividual.motivo = ''
            this.getListaNegra();
        }else if(data == "NO"){
              this.mensaje_warning = 'El Email No es Valido'
              this.mostrarNotificacionAlerta += 1;
              evt.target.disabled = false;
        }else if(data == "DUPLICADO"){
              this.mensaje_warning = 'El Email Ya Existe en la Lista Bloqueados'
              this.mostrarNotificacionAlerta += 1;
              evt.target.disabled = false;
        }else{
              let mensajeError = '';
              let dataError = response.data;
              for (const key in dataError) {
                if (Object.hasOwnProperty.call(dataError, key)) {
                  const element = dataError[key];
                  //mensajeError += `${element.mensaje} \n`
                  for (const iterator of element) {
                    mensajeError += `${iterator} \n`
                  }
                }
              }
              this.mensaje_warning = mensajeError
              this.mostrarNotificacionAlerta += 1;
              evt.target.disabled = false;
        }
      }).catch(error=>{this.mostrarNotificacionError ++;evt.target.disabled = false;console.log(error);}).finally(() =>{this.$store.dispatch('auth/loading_http','hidden')})
    },
    crearNuevoContactosArchivos(evt){
      evt = (evt) ? evt : window.event;
      evt.target.disabled = true;
      if (this.formCrearContactosArchivo.archivo == null) {
        this.mensaje_warning = 'Debe Seleccionar un Archivo';
        this.mostrarNotificacionAlerta += 1;
        evt.target.disabled = false;
        return;
      }
      let InstFormData = new FormData();
      InstFormData.append('archivo' , this.formCrearContactosArchivo.archivo);
      this.$store.dispatch('auth/loading_http','visible');
      this.$http.post(this.$httpUrlMail+'/mail/lista/negra/numeros',InstFormData,{
       headers : {'content-type': 'multipart/form-data'},
       withCredentials: false
      }).then(response =>{
        let data = response.data;
        if (data == "OK") {
            this.mensaje_success='Contactos Archivo Cargando ...'
            evt.target.disabled = false;
            this.nombre_grupo = '';
            this.mostrarNotificacion ++;
            this.fileInputKey++
            this.formCrearContactosArchivo.archivo = null
            // repetir con el intervalo de 2 segundos
            let timerId = setInterval(() => this.getListaNegra(), 5000);
            // después de 5 segundos parar
            setTimeout(() => { clearInterval(timerId);}, 60000);
        }else if(data == "NO"){
              this.mensaje_warning = 'El Email No es Valido en el Pais Seleccionado'
              this.mostrarNotificacionAlerta += 1;
              evt.target.disabled = false;
        }else if(data == "DUPLICADO"){
              this.mensaje_warning = 'El Email Ya Existe en el Grupo'
              this.mostrarNotificacionAlerta += 1;
              evt.target.disabled = false;
        }else{
              let mensajeError = '';
              let dataError = response.data;
              for (const key in dataError) {
                if (Object.hasOwnProperty.call(dataError, key)) {
                  const element = dataError[key];
                  //mensajeError += `${element.mensaje} \n`
                  for (const iterator of element) {
                    mensajeError += `${iterator} \n`
                  }
                }
              }
              this.mensaje_warning = mensajeError
              this.mostrarNotificacionAlerta += 1;
              evt.target.disabled = false;
        }
      }).catch(error=>{this.mostrarNotificacionError ++;evt.target.disabled = false;}).finally(() =>this.$store.dispatch('auth/loading_http','hidden'))
    },
    onChangeArchivoGrupo(event){
      this.formCrearContactosArchivo.archivo = event.target.files[0];
    },
    generarLimites(){
      this.listaLimiteQuery = [];
      let cantidad = this.file_all_grupo.cantidad
      let global = 1000;
      if (cantidad > global) {
        this.validaLimiteQuery = 'block';
        let recorre = Math.ceil(cantidad/global);
        let sumtemp = 1;
        let antes = 1;
        let despues = global;
        for (let index = 0; index < recorre; index++) {
          if (sumtemp < cantidad) {
            const element = {value:`${antes},${despues}`,label:`${antes} -- ${despues}`};
            this.listaLimiteQuery.push(element);
          }else{
            const element = {value:`${antes},${cantidad}`,label:`${antes} -- ${cantidad}`};
            this.listaLimiteQuery.push(element);
          }
          antes = despues;
          sumtemp = despues + global;
          despues = sumtemp;
        }
      }else{
        this.validaLimiteQuery = 'none';
      }
    },
    filtroItemsQuery(event){
      let value = event.target.value;
      let data = value.split(",");
      this.getInitListaNegra();
    },
    filtrobusquedalistanegra(event){
      let value = event.target.value;
      if (value.length > 3) {
         this.getInitListaNegra(value);
      }
    },
    validateEmail(email) {
      let mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if(email.match(mailformat)){
        return true;
      }
      return false;
    },
  },
  mounted(){
    setTimeout(() => {
      this.getInitListaNegra();
    }, 100);
  },
  created: function() {
        EventBus.$on('mostrarNotificacion', (item) => {
          console.log(item);
            //this.comments.push(item)
        });
  },
  computed: {
    changeRadioMetodoEnvio(){
      let metodo_envido_checked = 0;
      if (this.metodo_envido_checked_radio == 'individual') {
        metodo_envido_checked = 1;
      }else if(this.metodo_envido_checked_radio == 'archivo'){
        metodo_envido_checked = 2;
      }
      return metodo_envido_checked;
    }
  }
}
</script>
